export const mutations = {
    deleteNotify(state, payload) {
        let i = state.notifies.findIndex(item => item.text === payload)
        if (i >= 0) {
            let _notifies = [...state.notifies]
            _notifies.splice(i, 1)
            state.notifies = _notifies;
        }
    },
    addNotify(state, payload) {
        state.notifies.push(payload);
        setTimeout(() => {
            this.commit('deleteNotify', payload.text);
        }, state.timeShowNotify)
    },


    setDefaultProductSale(state, payload) {
        state.defaultProductSale = payload
    },


    setCrmProductSalesTotal(state, payload) {
        state.crmProductSalesTotal = payload
    },
    setCrmProductSales(state, payload) {
        state.crmProductSales = [...state.crmProductSales, ...payload]
    },

    setCrmUsersTotal(state, payload) {
        state.crmUsersTotal = payload
    },
    setCrmUsers(state, payload) {
        state.crmUsers = [...state.crmUsers, ...payload]
    },
    setContactUserFields(state, payload) {
        state.contactUserFields = payload
    },
    setCompanyUserFields(state, payload) {
        state.companyUserFields = payload
    },
    setDealUserFields(state, payload) {
        state.dealUserFields = payload
    },

    setAttributesField(state, payload) {
        state.attributesField = [...state.attributesField, ...payload]
    },
    setAttributesFieldTotal(state, payload) {
        state.attributesFieldTotal = payload
    },

    setAttributesElementField(state, payload) {
        state.attributesElementField = [...state.attributesElementField, ...payload]
    },
    setAttributesElementFieldTotal(state, payload) {
        state.attributesElementFieldTotal = payload
    },


    setShippersField(state, payload) {
        state.shippersField = [...state.shippersField, ...payload]
    },
    setShippersFieldTotal(state, payload) {
        state.shippersFieldTotal = payload
    },

    setMaterialsField(state, payload) {
        state.materialsField = [...state.materialsField, ...payload]
    },
    setMaterialsFieldTotal(state, payload) {
        state.materialsFieldTotal = payload
    },

    setCurrentDeal(state, payload) {
        console.log('setCurrentDeal', payload);
        state.currentDeal = payload
    },
    setCurrentCompany(state, payload) {
        state.currentCompany = payload
    },
    setCurrentContacts(state, payload) {
        if(payload.ID){
            if (state.currentContacts.find(contact => contact.ID * 1 === payload.ID * 1)) {
                state.currentContacts = state.currentContacts.map(contact => {
                    return contact.ID * 1 === payload.ID * 1 ? payload : contact
                })
            }else{
                state.currentContacts.push(payload)
            }
        }
    },



}

