<template>
  <div></div>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
export default {
  name: "PreloadEntities",
  methods: {
    getArrFieldByObject: function (obj) {
      let arr = [];
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          let tmp = obj[key];
          if (tmp.formLabel)
            tmp.title = tmp.formLabel;
          tmp.key = key;
          arr.push(tmp)
        }
      }
      return arr;
    },
    formatDateForDataPicker(dateString) {
      if (!dateString)
        return 0;


      let parts = dateString.split('.');

      if (parts.length < 3)
        return 0;

      return Date.parse(new Date(parts[2], parts[1] - 1, parts[0]));
    },

    isDatesAvailable(date_active_from,date_active_to){
      let today = Date.parse(new Date());
      let dateActiveFrom = this.formatDateForDataPicker(date_active_from);
      let dateActiveTo = this.formatDateForDataPicker(date_active_to);

      let result = true;

      if(dateActiveFrom && dateActiveFrom > today){
           result = false;
      }
      if(dateActiveTo && dateActiveTo < today){
        result = false;
      }

      return result;
    }

  },
  created() {
    BX24.init(() => {
      console.log('INIT')
      if (localStorage.contactFields === undefined) {
        BX24.callMethod(
            "crm.contact.fields", {order: {"SORT": "ASC"}, filter: {"MANDATORY": "N"}},
            (result) => {
              if (result.error()) {
                console.log('error crm.contact.fields', result.error());
                alert("Ошибка в консоле");
              } else {
                this.$store.commit('setContactUserFields', this.getArrFieldByObject(result.data()))
                localStorage.contactFields = JSON.stringify(this.$store.state.contactUserFields)
              }
            }
        );
      } else {
        this.$store.commit('setContactUserFields', JSON.parse(localStorage.contactFields))
      }


      if (localStorage.companyFields === undefined) {
        BX24.callMethod(
            "crm.company.fields", {order: {"SORT": "ASC"}, filter: {"MANDATORY": "N"}},
            (result) => {
              if (result.error()) {
                console.log('error crm.company.fields', result.error());
                alert("Ошибка в консоле");
              } else {
                this.$store.commit('setCompanyUserFields', this.getArrFieldByObject(result.data()))
                localStorage.companyFields = JSON.stringify(this.$store.state.companyUserFields)
              }
            }
        );
      } else {
        this.$store.commit('setCompanyUserFields', JSON.parse(localStorage.companyFields))
      }


      //if (localStorage.materials === undefined) {
      BX24.callMethod(
          "mindentity.item.get",
          {
            ENTITY: "product_material",
          },
          (result) => {
            if (result.error()) {
              console.log('error mindentity.item.get', result.error());
              alert("Ошибка в консоле");
            } else {
              if (this.$store.state.materialsFieldTotal === null)
                this.$store.commit('setMaterialsFieldTotal', result.total())

              this.$store.commit('setMaterialsField', result.data())

              console.log('get product_material ', result.total(), result.answer && result.answer.next ? result.answer.next : '-');

              if (result.more())
                result.next();
              // else
              //     localStorage.materials = JSON.stringify(this.$store.state.materialsField)
            }
          }
      );
      // }
      // else {
      //     this.$store.commit('setMaterialsField', JSON.parse(localStorage.materials))
      // }

      if (localStorage.attributes === undefined) {
        let setAttributesFieldArr = [];
        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_attribute",
              FILTER: {
                PROPERTY_is_visible_in_product_app: "YES",
              },
            },
            (result) => {
              if (result.error()) {
                console.log('error mindentity.item.get', result.error());
                alert("Ошибка в консоле");
              } else {
                if (this.$store.state.attributesFieldTotal === null)
                  this.$store.commit('setAttributesFieldTotal', result.total())

                let attrIds = [
                  "133473",
                  "133311",
                  "133470",
                  "133323",
                  "133373",
                  "133308",
                  "133386",
                  "133484",
                  "133313",
                  "133633",
                  "133365",
                  "133316",
                  "133350",
                  "133309",
                  "133375",
                  "133461",
                  "133387",
                ]


                let setAttributesFieldArr = [];
                for (let attr of result.data()) {
                  setAttributesFieldArr.push(attr);
                }

                if (setAttributesFieldArr.length !== 0) {
                  this.$store.commit('setAttributesField', setAttributesFieldArr)
                }

                if (result.more())
                  result.next();
                else
                  localStorage.attributes = JSON.stringify(this.$store.state.attributesField)
              }
            }
        );
      } else {
        let attrIds = [
          "133473",
          "133311",
          "133470",
          "133323",
          "133373",
          "133308",
          "133386",
          "133484",
          "133313",
          "133633",
          "133365",
          "133316",
          "133350",
          "133309",
          "133375",
          "133461",
          "133387",
        ]
        let setAttributesFieldArr = [];
        for (let attr of JSON.parse(localStorage.attributes)) {
          if (attrIds.includes(attr.ID)) {
            setAttributesFieldArr.push(attr);
          }
        }
        this.$store.commit('setAttributesField', setAttributesFieldArr);
      }

      if (localStorage.attributesElements === undefined) {
        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_elemnents_attribute",
              FILTER: {
                PROPERTY_is_visible_in_product_app: "YES",
              },
            },
            (result) => {
              if (result.error()) {
                console.log('error mindentity.item.get', result.error());
                alert("Ошибка в консоле");
              } else {
                if (this.$store.state.attributesElementFieldTotal === null)
                  this.$store.commit('setAttributesElementFieldTotal', result.total())

                console.log('result.data()');
                console.log(result.data());
                this.$store.commit('setAttributesElementField', result.data())

                if (result.more())
                  result.next();
                else
                  localStorage.attributesElements = JSON.stringify(this.$store.state.attributesElementField)
              }
            }
        );
      } else {
        this.$store.commit('setAttributesElementField', JSON.parse(localStorage.attributesElements))
      }

      if (localStorage.shippers === undefined) {
        BX24.callMethod(
            "mindentity.item.get",
            {
              ENTITY: "product_shipper",
            },
            (result) => {
              if (result.error()) {
                console.log('error mindentity.item.get', result.error());
                alert("Ошибка в консоле");
              } else {
                if (this.$store.state.shippersFieldTotal === null)
                  this.$store.commit('setShippersFieldTotal', result.total())

                this.$store.commit('setShippersField', result.data())

                if (result.more())
                  result.next();
                else
                  localStorage.shippers = JSON.stringify(this.$store.state.shippersField)
              }
            }
        );
      } else {
        this.$store.commit('setShippersField', JSON.parse(localStorage.shippers))
      }


      if (localStorage.dealFields === undefined) {
        BX24.callMethod(
            "crm.deal.fields", {order: {"SORT": "ASC"}, filter: {"MANDATORY": "N"}},
            (result) => {
              if (result.error()) {
                console.log('error crm.deal.fields', result.error());
                alert("Ошибка в консоле");
              } else {
                this.$store.commit('setDealUserFields', this.getArrFieldByObject(result.data()))
                localStorage.dealFields = JSON.stringify(this.$store.state.dealUserFields)
              }
            }
        );
      } else {
        this.$store.commit('setDealUserFields', JSON.parse(localStorage.dealFields))
      }


      if (localStorage.crmUsers === undefined) {
        BX24.callMethod(
            "user.search", {FILTER: []},
            (result) => {
              if (result.error()) {
                console.log('error user.search', result.error());
                alert("Ошибка в консоле");
              } else {
                let _users = result.data();
                _users.forEach(item => {
                  item.FULLNAME = item.LAST_NAME + ' ' + item.NAME;
                })

                if (this.$store.state.crmUsersTotal === null)
                  this.$store.commit('setCrmUsersTotal', result.total())

                this.$store.commit('setCrmUsers', _users)

                if (result.more())
                  result.next();
                else
                  localStorage.crmUsers = JSON.stringify(this.$store.state.crmUsers)
              }
            }
        );
      } else {
        let crmUsers = JSON.parse(localStorage.crmUsers)
        this.$store.commit('setCrmUsersTotal', crmUsers.length)
        this.$store.commit('setCrmUsers', crmUsers)
      }

    });

    BX24.init(() => {

      let today = (new Date()).toISOString().split('T')[0]
      let filter = {
        "!NAME":"",
        "0": {
          "logic": "OR",
          "0": {
            '<=PROPERTY_date_active_from': today
          },
          "1": {
            '>=PROPERTY_date_active_to': today
          }
        }

      };
      BX24.callMethod("mindentity.item.get",
          {
            ENTITY: "product_sales",
            // FILTER: filter
          },
          (result) => {
            if (result.error()) {
              console.log('mindentity.item.get product_sales', result.error());
              alert("Ошибка в консоле");
            } else {
              if (this.$store.state.crmProductSalesTotal === null) {
                this.$store.commit('setCrmProductSalesTotal', result.total())
              }


              result = result.data().filter((sale) => {
                return  sale.PROPERTY_VALUES && sale.PROPERTY_VALUES.id_product === ""
                    && this.isDatesAvailable(sale.PROPERTY_VALUES.date_active_from,(sale.PROPERTY_VALUES.date_active_to))
              });
              console.log('result',result);

              this.$store.commit('setCrmProductSales', result);
            }
          }
      );


      BX24.callMethod("mindentity.item.get",
          {
            ENTITY: "product_sales",
            FILTER: {ID:this.$store.state.defaultProductSaleId}
          },
          (result) => {
            if (result.error()) {
              console.log('mindentity.item.get product_sales', result.error());
              alert("Ошибка в консоле");
            } else {
              console.log('defaultProductSaleId',result);
              if(result.data()[0]){
                let item = result.data()[0];
                this.$store.commit('setDefaultProductSale',  {
                  label: item.NAME,
                  value: item.ID,
                  sale: item.PROPERTY_VALUES.sale ? item.PROPERTY_VALUES.sale * 1 : 0,
                });
              }
              else {
                alert("Ошибка в консоле");
                console.log('Не нашлась дефолтная акция!');
              }

            }
          }
      );



    });
  }

}
</script>

<style scoped>

</style>
